<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div class="pxkeji-right-box">
      <a-tabs default-active-key="alipay">
        <a-tab-pane key="alipay" tab="支付宝支付" force-render>
          <a-form-model
            ref="alipayForm"
            :model="info"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item label="appId" prop="alipay.appId">
              <a-input
                placeholder="请输入"
                v-model="info.alipay.appId"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item label="支付公钥" prop="alipay.publicKey">
              <a-textarea
                placeholder="请输入"
                :rows="4"
                v-model="info.alipay.publicKey"
              ></a-textarea>
            </a-form-model-item>
            <a-form-model-item label="支付私钥" prop="alipay.privateKey">
              <a-textarea
                placeholder="请输入"
                :rows="4"
                v-model="info.alipay.privateKey"
              ></a-textarea>
            </a-form-model-item>
            <a-form-model-item label="回调地址" prop="alipay.notifyUrl">
              <a-input
                placeholder="请输入"
                v-model="info.alipay.notifyUrl"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item label="验签类型" prop="alipay.signType">
              <a-select v-model="info.alipay.signType" placeholder="请选择">
                <a-select-option value="RSA">RSA</a-select-option>
                <a-select-option value="RSA2">RSA2</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item :wrapper-col="{ span: 10, offset: 4 }">
              <pxkeji-btn name="保存" type="primary" @click="aliSubmit" />
            </a-form-model-item>
          </a-form-model>
        </a-tab-pane>
        <a-tab-pane key="wechat" tab="微信支付" force-render>
          <a-form-model
            ref="wechatForm"
            :model="info"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item label="商户ID" prop="wechat.mchId">
              <a-input
                placeholder="请输入"
                v-model="info.wechat.mchId"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item label="商户密钥" prop="wechat.mchKey">
              <a-input
                placeholder="请输入"
                v-model="info.wechat.mchKey"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item label="移动应用appId" prop="wechat.appId">
              <a-input
                placeholder="请输入"
                v-model="info.wechat.appId"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item label="小程序appId" prop="wechat.appletsAppId">
              <a-input
                placeholder="请输入"
                v-model="info.wechat.appletsAppId"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item label="公众号appId" prop="wechat.mpAppId">
              <a-input
                placeholder="请输入"
                v-model="info.wechat.mpAppId"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item label="回调地址" prop="wechat.notifyUrl">
              <a-input
                placeholder="请输入"
                v-model="info.wechat.notifyUrl"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item label="微信证书" prop="wechat.keyContent">
              <a-textarea
                placeholder="请输入"
                :rows="4"
                v-model="info.wechat.keyContent"
              ></a-textarea>
            </a-form-model-item>
            <a-form-model-item :wrapper-col="{ span: 10, offset: 4 }">
              <pxkeji-btn name="保存" type="primary" @click="wechatSubmit" />
            </a-form-model-item>
          </a-form-model>
        </a-tab-pane>
        <a-tab-pane key="wxmp" tab="微信授权" force-render>
          <a-form-model
            ref="wxmpForm"
            :model="info"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item label="公众号appId" prop="wxmp.appId">
              <a-input placeholder="请输入" v-model="info.wxmp.appId"></a-input>
            </a-form-model-item>
            <a-form-model-item label="公众号secret" prop="wxmp.secret">
              <a-input
                placeholder="请输入"
                v-model="info.wxmp.secret"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item label="小程序appId" prop="wxmp.appletsAppId">
              <a-input
                placeholder="请输入"
                v-model="info.wxmp.appletsAppId"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item label="小程序secret" prop="wxmp.appletsSecret">
              <a-input
                placeholder="请输入"
                v-model="info.wxmp.appletsSecret"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item label="网站应用appId" prop="wxmp.websiteAppId">
              <a-input
                placeholder="请输入"
                v-model="info.wxmp.websiteAppId"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item label="网站应用secret" prop="wxmp.websiteSecret">
              <a-input
                placeholder="请输入"
                v-model="info.wxmp.websiteSecret"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item :wrapper-col="{ span: 10, offset: 4 }">
              <pxkeji-btn name="保存" type="primary" @click="wxmpSubmit" />
            </a-form-model-item>
          </a-form-model>
        </a-tab-pane>
        <a-tab-pane key="qqConfig" tab="QQ授权" force-render>
          <a-form-model
            ref="qqConfigForm"
            :model="info"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item label="移动应用appId" prop="qqConfig.appId">
              <a-input
                placeholder="请输入"
                v-model="info.qqConfig.appId"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item label="移动应用secret" prop="qqConfig.secret">
              <a-input
                placeholder="请输入"
                v-model="info.qqConfig.secret"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item
              label="网站应用appId"
              prop="qqConfig.websiteAppId"
            >
              <a-input
                placeholder="请输入"
                v-model="info.qqConfig.websiteAppId"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item
              label="网站应用secret"
              prop="qqConfig.websiteSecret"
            >
              <a-input
                placeholder="请输入"
                v-model="info.qqConfig.websiteSecret"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item :wrapper-col="{ span: 10, offset: 4 }">
              <pxkeji-btn name="保存" type="primary" @click="qqConfigSubmit" />
            </a-form-model-item>
          </a-form-model>
        </a-tab-pane>
        <a-tab-pane key="sms" tab="短信" force-render>
          <a-form-model
            ref="smsForm"
            :model="info"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item label="类型" prop="sms.type">
              <a-select placeholder="请选择" v-model="info.sms.type">
                <a-select-option value="yimei">亿美短信服务</a-select-option>
                <a-select-option value="aliyun">阿里云短信服务</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="账户" prop="sms.account">
              <a-input
                placeholder="请输入"
                v-model="info.sms.account"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item label="密钥" prop="sms.secret">
              <a-input placeholder="请输入" v-model="info.sms.secret"></a-input>
            </a-form-model-item>
            <a-form-model-item label="短信签名" prop="sms.sign">
              <a-input placeholder="请输入" v-model="info.sms.sign"></a-input>
            </a-form-model-item>
            <a-form-model-item :wrapper-col="{ span: 10, offset: 4 }">
              <pxkeji-btn name="保存" type="primary" @click="smsSubmit" />
            </a-form-model-item>
          </a-form-model>
        </a-tab-pane>
        <a-tab-pane key="apppush" tab="APP推送" force-render>
          <a-form-model
            ref="pushForm"
            :model="info"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item label="推送类型" prop="appPush.type">
              <a-select v-model="info.appPush.type">
                <a-select-option value="jpush">极光推送</a-select-option>
                <a-select-option value="unipush">uni推送</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="secret" prop="appPush.secret">
              <a-input
                placeholder="请输入secret"
                v-model="info.appPush.secret"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item label="key" prop="appPush.appKey">
              <a-input
                placeholder="请输入key"
                v-model="info.appPush.appKey"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item label="服务接入点" prop="appPush.domainUrl">
              <a-input
                placeholder="请输入服务接入点"
                v-model="info.appPush.domainUrl"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item
              :wrapper-col="{ span: 10, offset: 4 }"
              style="margin-top: 20px"
            >
              <pxkeji-btn name="保存" type="primary" @click="pushSubmit" />
            </a-form-model-item>
          </a-form-model>
        </a-tab-pane>
        <a-tab-pane key="liveType" tab="直播方式" force-render>
          <a-form-model
            ref="tencentVideoForm"
            :model="info"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-divider orientation="left">基本设置</a-divider>
            <a-row>
              <a-col :span="12">
                <a-form-model-item
                  label="appId"
                  prop="tencentVideoConfig.appId"
                >
                  <a-input
                    placeholder="请输入"
                    v-model="info.tencentVideoConfig.appId"
                  ></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item
                  label="接口秘钥id"
                  prop="tencentVideoConfig.secretId"
                >
                  <a-input
                    placeholder="请输入"
                    v-model="info.tencentVideoConfig.secretId"
                  ></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item
                  label="接口秘钥"
                  prop="tencentVideoConfig.secretKey"
                >
                  <a-input
                    placeholder="请输入"
                    v-model="info.tencentVideoConfig.secretKey"
                  ></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item
                  label="直播推流域名"
                  prop="tencentVideoConfig.pushDomain"
                >
                  <a-input
                    placeholder="请输入"
                    v-model="info.tencentVideoConfig.pushDomain"
                  ></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item
                  label="推流地址加密秘钥"
                  prop="tencentVideoConfig.pushIdentityKey"
                >
                  <a-input
                    placeholder="请输入"
                    v-model="info.tencentVideoConfig.pushIdentityKey"
                  ></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item
                  label="直播拉流域名"
                  prop="tencentVideoConfig.pullDomain"
                >
                  <a-input
                    placeholder="请输入"
                    v-model="info.tencentVideoConfig.pullDomain"
                  ></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item
                  label="拉流地址加密秘钥"
                  prop="tencentVideoConfig.pullIdentityKey"
                >
                  <a-input
                    placeholder="请输入"
                    v-model="info.tencentVideoConfig.pullIdentityKey"
                  ></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item
                  label="拉流地址有效期"
                  prop="tencentVideoConfig.urlValidTime"
                >
                  <a-input
                    placeholder="请输入"
                    v-model="info.tencentVideoConfig.urlValidTime"
                  ></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item
                  label="续播间隔时间"
                  prop="tencentVideoConfig.intervalTime"
                >
                  <a-input
                    placeholder="请输入"
                    v-model="info.tencentVideoConfig.intervalTime"
                  ></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="直播回放域名">
                  <a-input
                    placeholder="请输入直播回放域名"
                    v-model="info.tencentVideoConfig.playbackDomain"
                  ></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-divider orientation="left">云直播配置</a-divider>
            <a-row>
              <a-col :span="12">
                <a-form-model-item
                  label="请输入直播名称"
                  prop="tencentVideoConfig.liveConfig.appName"
                >
                  <a-input
                    placeholder="请输入"
                    disabled
                    v-model="info.tencentVideoConfig.liveConfig.appName"
                  ></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-divider orientation="left">实时音视频配置</a-divider>
            <a-row>
              <a-col :span="12">
                <a-form-model-item
                  label="应用SDKAppID"
                  prop="tencentVideoConfig.trtcConfig.sdkAppId"
                >
                  <a-input
                    placeholder="请输入"
                    v-model="info.tencentVideoConfig.trtcConfig.sdkAppId"
                  ></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item
                  label="userSigKey"
                  prop="tencentVideoConfig.trtcConfig.userSigKey"
                >
                  <a-input
                    placeholder="请输入"
                    v-model="info.tencentVideoConfig.trtcConfig.userSigKey"
                  ></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item
                  label="登录票据的过期时间"
                  prop="tencentVideoConfig.trtcConfig.userSignExpire"
                >
                  <a-input
                    placeholder="请输入"
                    v-model="info.tencentVideoConfig.trtcConfig.userSignExpire"
                  ></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-divider orientation="left">云点播配置</a-divider>
            <a-row>
              <a-col :span="12">
                <a-form-model-item
                  label="防盗链签名key"
                  prop="tencentVideoConfig.vodConfig.antiLeechSignKey"
                >
                  <a-input
                    placeholder="请输入"
                    v-model="info.tencentVideoConfig.vodConfig.antiLeechSignKey"
                  ></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item
                  label="转码模板名称"
                  prop="tencentVideoConfig.vodConfig.taskFlowName"
                >
                  <a-select
                    v-model="info.tencentVideoConfig.vodConfig.taskFlowName"
                  >
                    <a-select-option
                      :value="tencentAdapt.value"
                      v-for="(tencentAdapt, index) in tencentAdaptTranscodeList"
                      :key="index"
                      >{{ tencentAdapt.name }}
                    </a-select-option>
                  </a-select>
                  <!-- <a-input
                    placeholder="请输入"
                    v-model="info.tencentVideoConfig.vodConfig.taskFlowName"
                  ></a-input> -->
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item
                  label="视频分类"
                  prop="tencentVideoConfig.vodConfig.classId"
                >
                  <a-input
                    placeholder="请输入"
                    v-model="info.tencentVideoConfig.vodConfig.classId"
                  ></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-form-model-item :wrapper-col="{ span: 10, offset: 4 }">
              <pxkeji-btn
                name="保存"
                type="primary"
                @click="tencentTRTCSubmit"
              />
            </a-form-model-item>
          </a-form-model>
        </a-tab-pane>
      </a-tabs>
    </div>
  </a-spin>
</template>
<script>
let _this;
export default {
  name: "thirdconfig",
  data() {
    return {
      isloading: false,
      loadingTip: "加载中...",
      labelCol: { span: 4 },
      wrapperCol: { xxl: 10, xl: 14 },
      tencentAdaptTranscodeList: [],
      info: {
        alipay: {
          appId: "",
          publicKey: "",
          privateKey: "",
          notifyUrl: "",
          signType: "RSA2",
        },
        appPush: {
          type: "jpush",
          appKey: "",
          secret: "",
          domainUrl: "",
        },
        wechat: {
          mchId: "",
          mchKey: "",
          appId: "",
          appletsAppId: "",
          mpAppId: "",
          notifyUrl: "",
          keyContent: "",
        },
        wxmp: {
          appId: "",
          secret: "",
          appletsAppId: "",
          appletsSecret: "",
          websiteAppId: "",
          websiteSecret: "",
        },
        qqConfig: {
          appId: "",
          secret: "",
          websiteAppId: "",
          websiteSecret: "",
        },
        sms: {
          type: null,
          account: "",
          secret: "",
          sign: "",
        },
        tencentVideoConfig: {
          appId: "",
          secretId: "",
          secretKey: "",
          pushDomain: "",
          pushIdentityKey: "",
          pullDomain: "",
          pullIdentityKey: "",
          urlValidTime: "",
          intervalTime: "",
          playbackDomain: "",
          liveConfig: {
            appName: "",
          },
          trtcConfig: {
            sdkAppId: "",
            userSigKey: "",
            userSignExpire: "",
          },
          vodConfig: {
            antiLeechSignKey: "",
            taskFlowName: "",
            classId: "",
          },
        },
      },
      rules: {
        alipay: {
          appId: [{ required: true, message: "请输入appId", trigger: "blur" }],
          publicKey: [
            { required: true, message: "请输入支付公钥", trigger: "blur" },
          ],
          privateKey: [
            { required: true, message: "请输入支付秘钥", trigger: "blur" },
          ],
          notifyUrl: [
            { required: true, message: "请输入回调地址", trigger: "blur" },
          ],
        },
        wechat: {
          mchId: [{ required: true, message: "请输入商户ID", trigger: "blur" }],
          mchKey: [
            { required: true, message: "请输入商户秘钥", trigger: "blur" },
          ],
          notifyUrl: [
            { required: true, message: "请输入回调地址", trigger: "blur" },
          ],
        },
        appPush: {
          appKey: [{ required: true, message: "请输入key", trigger: "blur" }],
          secret: [
            { required: true, message: "请输入secret", trigger: "blur" },
          ],
        },
        sms: {
          type: [
            { required: true, message: "请选择短信类型", trigger: "change" },
          ],
          account: [
            { required: true, message: "请输入账户号", trigger: "blur" },
          ],
          secret: [
            { required: true, message: "请输入账户秘钥", trigger: "blur" },
          ],
          sign: [
            { required: true, message: "请输入短信签名", trigger: "blur" },
          ],
        },

        tencentVideoConfig: {
          appId: [{ required: true, message: "请输入appId", trigger: "blur" }],
          secretId: [
            { required: true, message: "请输入secretId", trigger: "blur" },
          ],
          secretKey: [
            { required: true, message: "请输入secretKey", trigger: "blur" },
          ],
          pushDomain: [
            { required: true, message: "请输入推流域名", trigger: "blur" },
          ],
          pullDomain: [
            { required: true, message: "请输入拉流域名", trigger: "blur" },
          ],
          pushIdentityKey: [
            { required: true, message: "请输入推流秘钥", trigger: "blur" },
          ],
          pullIdentityKey: [
            { required: true, message: "请输入拉流秘钥", trigger: "blur" },
          ],
          urlValidTime: [
            { required: true, message: "请输入推拉流有效期", trigger: "blur" },
          ],
          intervalTime: [
            { required: true, message: "请输入续播间隔时间", trigger: "blur" },
          ],
          liveConfig: {
            appName: [
              { required: true, message: "请输入直播名称", trigger: "blur" },
            ],
          },
          trtcConfig: {
            sdkAppId: [
              { required: true, message: "请输入sdkAppId", trigger: "blur" },
            ],
            userSigKey: [
              { required: true, message: "请输入密钥", trigger: "blur" },
            ],
            userSignExpire: [
              {
                required: true,
                message: "请输入用户登录票据的过期时间",
                trigger: "blur",
              },
            ],
          },
          vodConfig: {
            antiLeechSignKey: [
              {
                required: true,
                message: "请输入防盗链签名key",
                trigger: "blur",
              },
            ],
            taskFlowName: [
              {
                required: true,
                message: "请选择转码模板名称",
                trigger: "change",
              },
            ],
            classId: [
              {
                required: true,
                message: "请输入视频分类",
                trigger: "blur",
              },
            ],
          },
        },
      },
    };
  },
  mounted() {
    _this = this;
    _this.getConfig();
    _this.getTencentAdaptTranscode();
  },
  methods: {
    getTencentAdaptTranscode() {
      _this.$api.systemSetting
        .getTencentAdaptTranscode()
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.tencentAdaptTranscodeList = res.data;
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {});
    },
    getConfig() {
      _this.isloading = true;
      _this.$api.systemSetting
        .getThirdConfig()
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            if (res.data.alipay) {
              _this.info.alipay = res.data.alipay;
            }
            if (res.data.wechat) {
              _this.info.wechat = res.data.wechat;
            }
            if (res.data.appPush) {
              _this.info.appPush = res.data.appPush;
            }
            if (res.data.wxmp) {
              _this.info.wxmp = res.data.wxmp;
            }
            if (res.data.qqConfig) {
              _this.info.qqConfig = res.data.qqConfig;
            }
            if (res.data.sms) {
              _this.info.sms = res.data.sms;
            }
            if (res.data.tencentVideoConfig) {
              this.info.tencentVideoConfig = res.data.tencentVideoConfig;
            }
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    /**支付宝支付 */
    aliSubmit() {
      _this.$refs.alipayForm.validate((valid) => {
        if (valid) {
          _this.isloading = true;
          _this.$api.systemSetting
            .setAlipayConfig(_this.info.alipay)
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg);
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.isloading = false;
            })
            .catch(() => {
              _this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },

    // 微信支付
    wechatSubmit() {
      _this.$refs.wechatForm.validate((valid) => {
        if (valid) {
          _this.isloading = true;
          _this.$api.systemSetting
            .setWechatConfig(_this.info.wechat)
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg);
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.isloading = false;
            })
            .catch(() => {
              _this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 微信授权
    wxmpSubmit() {
      _this.$refs.wxmpForm.validate((valid) => {
        if (valid) {
          _this.isloading = true;
          _this.$api.systemSetting
            .setWxMpConfig(_this.info.wxmp)
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg);
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.isloading = false;
            })
            .catch(() => {
              _this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },
    // QQ授权
    qqConfigSubmit() {
      _this.$refs.qqConfigForm.validate((valid) => {
        if (valid) {
          _this.isloading = true;
          _this.$api.systemSetting
            .setQQConfig(_this.info.qqConfig)
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg);
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.isloading = false;
            })
            .catch(() => {
              _this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },
    pushSubmit() {
      _this.$refs.pushForm.validate((valid) => {
        if (valid) {
          _this.isloading = true;
          _this.$api.systemSetting
            .setAppPushConfig(_this.info.appPush)
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg);
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.isloading = false;
            })
            .catch(() => {
              _this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 短信
    smsSubmit() {
      _this.$refs.smsForm.validate((valid) => {
        if (valid) {
          _this.isloading = true;
          _this.$api.systemSetting
            .setSmsConfig(_this.info.sms)
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg);
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.isloading = false;
            })
            .catch(() => {
              _this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },
    // tencentTRTC
    tencentTRTCSubmit() {
      _this.$refs.tencentVideoForm.validate((valid) => {
        if (valid) {
          _this.isloading = true;
          _this.$api.systemSetting
            .setTencentVideoConfig(_this.info.tencentVideoConfig)
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg);
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.isloading = false;
            })
            .catch(() => {
              _this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>


